import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Layout } from "@components";
import { CareerWrapper } from '@templates/career/CareerWrapper'
import { CareerWeCreateEnvironment } from '@templates/career/CareerWeCreateEnvironment'
import { CareerWeHelp } from '@templates/career/CareerWeHelp'
import { CareerBannerWantInTeam } from '@templates/career/CareerBannerWantInTeam'
import { CareerBannerCompany } from '@templates/career/CareerBannerCompany';
import { CareerOurValues } from '@templates/career/CareerOurValues';
import { CareerWhyChooseUs } from '@templates/career/CareerWhyChooseUs';
import { VacancySection, VacancyCard } from '@components';
import { CareerWhatWeDo } from '@templates/career/CareerWhatWeDo';
import { CareerWeCreateTrends } from '@templates/career/CareerWeCreateTrends';
import { CareerWorkWithUs } from '@templates/career/CareerWorkWithUs';
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";

import bg1 from '@images/career/page-bg-1.svg';
import bg1Mobile from '@images/career/page-bg-1-mobile.svg';
import bg2 from '@images/career/page-bg-2.svg';
import {CareerFormSection} from "@templates/career/CareerForm";

const vacancies = [
  {
    title: 'Менеджер по продажам B2B',
    location: 'Офис',
    department: 'Продажи',
    price: 'от 180 000',
    url: 'https://hh.ru/vacancy/116070106?hhtmFrom=employer\\_vacancies'
  },
  {
    title: 'Lead SMM',
    location: 'Удаленно',
    department: 'Маркетинг',
    price: 'от 150 000',
    url: 'https://hh.ru/vacancy/112489535?hhtmFrom=employer\\_vacancies'
  },
];

const HRPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />

        <title>
          Карьера в Самозанятые.рф — твой путь к успеху и развитию
        </title>

        <meta
          name="description"
          content="Хочешь развиваться в передовой IT-компании с офисом в центре Москвы и гибридным форматом работы? Самозанятые.рф — это уникальная экосистема для взаимодействия бизнеса и самозанятых, где ценят профессионализм, амбиции и творческий подход. ДМС, корпоративные мероприятия, новые технологии и свобода действий ждут тебя! Присоединяйся и достигай новых высот с нами! Отправь резюме сегодня!"
        />
      </Helmet>

      <Layout disableWidth={true}>
        <CareerPageSd>
          <CareerWrapper>
            <CareerPageWrapper>
              <CareerWeCreateEnvironment />

              <CareerWeHelp />

              <CareerWhyChooseUs />
            </CareerPageWrapper>
          </CareerWrapper>

          <CareerPageWrapper>
            <CareerWorkWithUs />
          </CareerPageWrapper>

          <CareerWrapper>
            <CareerPageWrapper>
              <CareerOurValues />

              <CareerWeCreateTrends />

              <CareerWhatWeDo />

              <CareerBannerCompany />

              <VacancySection>
                {
                  vacancies.map(item =>
                    <VacancyCard
                      title={item.title}
                      department={item.department}
                      location={item.location}
                      price={item.price}
                      url={item.url}
                      key={item.url}
                    />
                  )
                }
              </VacancySection>

              <CareerBannerWantInTeam />

              <CareerFormSection/>
            </CareerPageWrapper>
          </CareerWrapper>
        </CareerPageSd>
      </Layout>
    </>
  );
};

export default HRPage;

const CareerPageSd = styled.div`
  padding-top: 40px;

  background-repeat: no-repeat;

  background-image: url(${bg1Mobile});
  background-position: 50% 661px;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    padding-top: 30px;

    background-image: url(${bg1}), url(${bg2});
    background-position: 50% 211px, calc(50% - 250px) 5350px;
  }
`

const CareerPageWrapper = styled.div`
  --gap: 80px;

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  padding-bottom: var(--gap);

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    --gap: 160px;
  }
`
